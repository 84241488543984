import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import Highcharts, { SeriesPieOptions } from 'highcharts';

@Component<PieChart>({})
export default class PieChart extends Vue {
  @Prop()
  protected data?: any;

  @Prop({ default: false })
  protected haslegendLink?: boolean;

  @Prop({ default: 500 })
  protected height?: number;

  @Prop({ default: 200 })
  protected size?: number;

  @Prop()
  protected type?: string;

  protected chartData: any = [];

  protected chart: any;

  protected selectedOptions: string[] = [];

  protected legendActiveColor = 'rgb(0, 0, 0)';

  protected legendDefaultColor = '#616161';

  protected mounted() {
    this.chartData = cloneDeep(this.data);
    this.initialize();
  }

  protected initialize() {
    const chartContainer = (this.$refs.chart as any);
    if (! chartContainer) {
      return;
    }

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        type: 'pie',
        className: 'highcharts-pie',
        height: this.height,
        events: {
          load: (event: any) => {
            event.target.legend.contentGroup.element.childNodes[0].childNodes.forEach((item: any) => {
              const style = item.firstChild.style;
              item.onmouseover = () => {
                if (style.fill !== this.legendActiveColor) {
                  style.fill = '#313131';
                }
              };
              item.onmouseout = () => {
                if (style.fill !== this.legendActiveColor) {
                  style.fill = this.legendDefaultColor;
                }
              };
            });
          },
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        enabled: true,
        pointFormat: '{point.y}',
      },
      legend: {
        enabled: true,
        labelFormat: '{name} <span class="legend__value">({y})</span>',
        symbolRadius: 0,
        itemMarginBottom: 10,
        maxHeight: 400,
        itemStyle: {
          color: this.legendDefaultColor,
        },
        navigation: {
          activeColor: '#67A7C4',
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          center: [null, 80],
          borderWidth: 0,
          size: this.size,
          showInLegend: true,
          data: this.chartData,
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              mouseOut: (event: any) => {
                if (! event.target.selected) {
                  event.target.slice(false, false, {
                    duration: 500,
                  });
                }
              },
              mouseOver: (event: any) => {
                event.target.slice(true, true, {
                  duration: 500,
                });
              },
              click: (event: any) => {
                this.$emit('selectedFromPieChart', true);
                this.togglePieChart(event.point);
              },
              legendItemClick: (event: any) => {
                const point = event.target.series.data[event.target.index];
                if (this.haslegendLink) {
                  this.$emit('selectedCategory', event, point);
                } else {
                  this.$emit('selectedFromPieChart', true);
                }
                this.togglePieChart(point);
                return false;
              },
            },
          },
        } as SeriesPieOptions,
      ],
    }, () => {
    });
  }

  protected updateChart() {
    this.selectedOptions = [];
    this.chart.getSelectedPoints().forEach((serie: any) => {
      if (this.type !== 'statuses') {
        this.selectedOptions.push(serie.id);
      } else {
        this.selectedOptions.push(serie.key);
      }
    });

    this.$emit('selectedCategory', this.selectedOptions, this.type);
  }

  public setActive(value: string, removed = false) {
    if (value && removed) {
      this.togglePieChart(this.findPoint(value));
      return;
    }

    if (! value || this.selectedOptions.includes(value)) {
      return;
    }

    this.togglePieChart(this.findPoint(value));
  }

  protected findPoint(value: string) {
    const index = this.chartData.findIndex((category: any) => category.id === value || category.key === value);

    return this.chart.series[0].points[index];
  }

  protected togglePieChart(point: any) {
    // set active in legend
    const legendItem = point.legendItem.element;
    const selected = point.selected;

    point.legendGroup.parentGroup.element.childNodes.forEach((itemGroup: any) => {
      const text = itemGroup.firstChild;
      const style = text.style;

      if (text === legendItem) {
        style.fill = selected ? this.legendDefaultColor : this.legendActiveColor;
      }
    });

    // set selected in pie
    point.slice(null);
    point.select(null, true);

    // sets active styling
    this.styleActiveSlice(point);

    this.updateChart();
  }

  protected styleActiveSlice(point: any) {
    let count = 0;
    this.chart.series[0].data.forEach((seriePoint: any) => {
      if (seriePoint.selected === true) {
        count += 1;
      }

      if (point.selected) {
        if (! seriePoint.selected) {
          seriePoint.graphic.element.style.opacity = 0.3;
        } else {
          seriePoint.graphic.element.style.opacity = 1;
        }
      } else {
        point.graphic.element.style.opacity = 0.3;
      }
    });

    // reset
    if (count === 0) {
      this.chart.series[0].data.forEach((seriePoint: any) => {
        seriePoint.graphic.element.style.opacity = 1;
      });
    }
  }
}
